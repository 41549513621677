/*----------------------------------------------------------------*/
/*  Variables
/*----------------------------------------------------------------*/

// Typography
$font-family-body: 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
$font-family-code: 'Monaco', 'Menlo', 'Consolas', 'Ubuntu Mono', monospace;

// Toolbar
$toolbarHeight: 64px;

// Navigation Sidenav
$navigationWidth: 64px;
$navigationFoldedWidth: 64px;


$vega-gray0: #000000;
$vega-gray1: #212121;
$vega-gray2: #424242;
$vega-gray3: #616161;
$vega-gray4: #757575;
$vega-gray5: #9E9E9E;
$vega-gray6: #BDBDBD;
$vega-gray7: #E0E0E0;
$vega-gray8: #EEEEEE;
$vega-gray9: #F5F5F5;
$vega-gray10: #FAFAFA;
