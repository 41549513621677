@import '../../../bower_components/angular-material/modules/scss/angular-material.scss';
@import 'partials/variables';
@import 'partials/layouts-template';
@import 'partials/animations';
@import 'partials/reset';
@import 'partials/helpers';



.pb-8 {
  padding-bottom: 8px;
}

.pb-7 {
  padding-bottom: 7px;
}
