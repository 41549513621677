/*----------------------------------------------------------------*/
/*  Helpers
/*----------------------------------------------------------------*/
.no-margin {
  margin-right: 0;
}

.no-radius {
  border-radius: 0;
}

.divider-vertical {
  background: rgba(0, 0, 0, 0.12);
  height: 24px;
  width: 1px;
  margin: 0 8px;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

[fill-width] {
  min-width: 100%;
}

[fill-height] {
  min-height: 100%;
}

.full-height {
  height: 100% !important;
}

// Images in links
a {

  img {
    display: block;
    outline: none;
  }
}

// Responsive images
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none;
}

// Scrollable
.scrollable {
  display: block;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.only-x {
    overflow-x: auto;
    overflow-y: hidden;
  }

  &.only-y {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Flex Scrollable (without display:block)
.flex-scrollable {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.only-x {
    overflow-x: auto;
    overflow-y: hidden;
  }

  &.only-y {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Background Icon
.background-image-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

// Classic vertical align
.align-vertical {

  &:before {
    display: inline-block;
    height: 100%;
    content: '';
    vertical-align: middle;
  }

  .align-vertical-top,
  .align-vertical-middle,
  .align-vertical-bottom {
    display: inline-block;
  }

  .align-vertical-top {
    vertical-align: top;
  }

  .align-vertical-middle {
    vertical-align: middle;
  }

  .align-vertical-bottom {
    vertical-align: bottom;
  }
}
// Padding helpers

@for $i from 1 through 4 {

  .padding-#{$i},
  .p-#{$i} {
    padding: #{($i) + 'px'};
  }

  .padding-top-#{$i},
  .pt-#{$i} {
    padding-top: #{($i) + 'px'};
  }

  .padding-right-#{$i},
  .pr-#{$i} {
    padding-right: #{($i) + 'px'};
  }

  .padding-bottom-#{$i},
  .pb-#{$i} {
    padding-bottom: #{($i) + 'px'};
  }

  .padding-left-#{$i},
  .pl-#{$i} {
    padding-left: #{($i) + 'px'};
  }

  .margin-#{$i},
  .m-#{$i} {
    margin: #{($i) + 'px'};
  }

  .margin-top-#{$i},
  .mt-#{$i} {
    margin-top: #{($i) + 'px'};
  }

  .margin-right-#{$i},
  .mr-#{$i} {
    margin-right: #{($i) + 'px'};
  }

  .margin-bottom-#{$i},
  .mb-#{$i} {
    margin-bottom: #{($i) + 'px'};
  }

  .margin-left-#{$i},
  .ml-#{$i} {
    margin-left: #{($i) + 'px'};
  }
}

// Padding helpers
// Multiples of 5, max:50
@for $i from 0 through 10 {

  .padding-#{$i * 5},
  .p-#{$i * 5} {
    padding: #{($i * 5) + 'px'};
  }

  .padding-top-#{$i * 5},
  .pt-#{$i * 5} {
    padding-top: #{($i * 5) + 'px'};
  }

  .padding-right-#{$i * 5},
  .pr-#{$i * 5} {
    padding-right: #{($i * 5) + 'px'};
  }

  .padding-bottom-#{$i * 5},
  .pb-#{$i * 5} {
    padding-bottom: #{($i * 5) + 'px'};
  }

  .padding-left-#{$i * 5},
  .pl-#{$i * 5} {
    padding-left: #{($i * 5) + 'px'};
  }

  .padding-horizontal-#{$i * 5},
  .ph-#{$i * 5} {
    padding-left: #{($i * 5) + 'px'};
    padding-right: #{($i * 5) + 'px'};
  }

  .padding-vertical-#{$i * 5},
  .pv-#{$i * 5} {
    padding-top: #{($i * 5) + 'px'};
    padding-bottom: #{($i * 5) + 'px'};
  }
}

// Multiples of 8, Max: 48, Excluding 0 and multiples of 5
@for $i from 1 through 6 {

  @if (($i * 8) % 5 != 0) {

    .padding-#{$i * 8},
    .p-#{$i * 8} {
      padding: #{($i * 8) + 'px'};
    }

    .padding-top-#{$i * 8},
    .pt-#{$i * 8} {
      padding-top: #{($i * 8) + 'px'};
    }

    .padding-right-#{$i * 8},
    .pr-#{$i * 8} {
      padding-right: #{($i * 8) + 'px'};
    }

    .padding-bottom-#{$i * 8},
    .pb-#{$i * 8} {
      padding-bottom: #{($i * 8) + 'px'};
    }

    .padding-left-#{$i * 8},
    .pl-#{$i * 8} {
      padding-left: #{($i * 8) + 'px'};
    }

    .padding-horizontal-#{$i * 8},
    .ph-#{$i * 8} {
      padding-left: #{($i * 8) + 'px'};
      padding-right: #{($i * 8) + 'px'};
    }

    .padding-vertical-#{$i * 8},
    .pv-#{$i * 8} {
      padding-top: #{($i * 8) + 'px'};
      padding-bottom: #{($i * 8) + 'px'};
    }
  }
}

// Margin helpers
// Multiples of 5, max:50
@for $i from 0 through 10 {

  .margin-#{$i * 5},
  .m-#{$i * 5} {
    margin: #{($i * 5) + 'px'};
  }

  .margin-top-#{$i * 5},
  .mt-#{$i * 5} {
    margin-top: #{($i * 5) + 'px'};
  }

  .margin-right-#{$i * 5},
  .mr-#{$i * 5} {
    margin-right: #{($i * 5) + 'px'};
  }

  .margin-bottom-#{$i * 5},
  .mb-#{$i * 5} {
    margin-bottom: #{($i * 5) + 'px'};
  }

  .margin-left-#{$i * 5},
  .ml-#{$i * 5} {
    margin-left: #{($i * 5) + 'px'};
  }

  .margin-horizontal-#{$i * 5},
  .mh-#{$i * 5} {
    margin-left: #{($i * 5) + 'px'};
    margin-right: #{($i * 5) + 'px'};
  }

  .margin-vertical-#{$i * 5},
  .mv-#{$i * 5} {
    margin-top: #{($i * 5) + 'px'};
    margin-bottom: #{($i * 5) + 'px'};
  }
}

// Multiples of 8, Max: 48, Excluding 0 and multiples of 5
@for $i from 1 through 6 {

  @if (($i * 8) % 5 != 0) {

    .margin-#{$i * 8},
    .m-#{$i * 8} {
      margin: #{($i * 8) + 'px'};
    }

    .margin-top-#{$i * 8},
    .mt-#{$i * 8} {
      margin-top: #{($i * 8) + 'px'};
    }

    .margin-right-#{$i * 8},
    .mr-#{$i * 8} {
      margin-right: #{($i * 8) + 'px'};
    }

    .margin-bottom-#{$i * 8},
    .mb-#{$i * 8} {
      margin-bottom: #{($i * 8) + 'px'};
    }

    .margin-left-#{$i * 8},
    .ml-#{$i * 8} {
      margin-left: #{($i * 8) + 'px'};
    }

    .margin-horizontal-#{$i * 8},
    .mh-#{$i * 8} {
      margin-left: #{($i * 8) + 'px'};
      margin-right: #{($i * 8) + 'px'};
    }

    .margin-vertical-#{$i * 8},
    .mv-#{$i * 8} {
      margin-top: #{($i * 8) + 'px'};
      margin-bottom: #{($i * 8) + 'px'};
    }
  }
}

// Height and width helpers
// Multiples of 5, Max: 100
@for $i from 0 through 20 {

  .height-#{$i * 5},
  .h-#{$i * 5} {
    height: #{($i * 5) + 'px'} !important;
    min-height: #{($i * 5) + 'px'} !important;
    max-height: #{($i * 5) + 'px'} !important;
  }

  .width-#{$i * 5},
  .w-#{$i * 5} {
    width: #{($i * 5) + 'px'} !important;
    min-width: #{($i * 5) + 'px'} !important;
    max-width: #{($i * 5) + 'px'} !important;
  }
}

// Height and width helpers
// Multiples of 8, Max: 160, Excluding 0 and multiples of 5
@for $i from 1 through 20 {

  @if (($i * 8) % 5 != 0) {

    .height-#{$i * 8},
    .h-#{$i * 8} {
      height: #{($i * 8) + 'px'} !important;
      min-height: #{($i * 8) + 'px'} !important;
      max-height: #{($i * 8) + 'px'} !important;
    }

    .width-#{$i * 8},
    .w-#{$i * 8} {
      width: #{($i * 8) + 'px'} !important;
      min-width: #{($i * 8) + 'px'} !important;
      max-width: #{($i * 8) + 'px'} !important;
    }
  }
}

//Min width and height
@for $i from 1 through 8 {

  .min-height-#{$i * 100},
  .mh-#{$i * 100} {
    min-height: #{($i * 100) + 'px'} !important;
  }

  .min-width-#{$i * 100},
  .mw-#{$i * 100} {
    min-width: #{($i * 100) + 'px'} !important;
  }
  .max-height-#{$i * 100},
  .mxh-#{$i * 100} {
    max-height: #{($i * 100) + 'px'} !important;
  }

  .max-width-#{$i * 100},
  .mxw-#{$i * 100} {
    max-width: #{($i * 100) + 'px'} !important;
  }


  .height-#{$i * 100},
  .h-#{$i * 100} {
    height: #{($i * 100) + 'px'} !important;
    min-height: #{($i * 100) + 'px'} !important;
    max-height: #{($i * 100) + 'px'} !important;
  }

  .width-#{$i * 100},
  .w-#{$i * 100} {
    width: #{($i * 100) + 'px'} !important;
    min-width: #{($i * 100) + 'px'} !important;
    max-width: #{($i * 100) + 'px'} !important;
  }
}


// Border helpers
$border-style: 1px solid rgba(0, 0, 0, 0.12);

.border,
.b {
  border: $border-style;
}

.border-top,
.bt {
  border-top: $border-style;
}

.border-right,
.br {
  border-right: $border-style;
}

.border-bottom,
.bb {
  border-bottom: $border-style;
}

.border-left,
.bl {
  border-left: $border-style;
}

.border-horizontal,
.bh {
  border-left: $border-style;
  border-right: $border-style;
}

.border-vertical,
.bv {
  border-top: $border-style;
  border-bottom: $border-style;
}

// Border-radius helpers
@for $i from 1 through 12 {

  .brad-#{$i} {
    border-radius: #{$i + 'px'}
  }
}
