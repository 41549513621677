#forgot-password, #reset-password {
  height: 100%;  
  background-size: cover;

  #password-form-wrapper {
    flex: 1 0 auto;
    padding: 32px;

    #password-form {
      width: 384px;
      max-width: 384px;
      padding: 32px;
      text-align: center;
      background: #FFFFFF;

      .returnButton {
        padding: 0px 8px 0px 8px;
        text-decoration: none;
        text-transform: none;
        border: none;
      }

      #logo {
        width: 200px;
        height: 70px;
        line-height: 100px;
        font-size: 86px;
        font-weight: 500;
        margin: 32px auto;
        margin-bottom: 0;
        color: #FFFFFF;
        border-radius: 2px;
      }

      .title {
        font-size: 17px;
        margin: 16px 0 32px 0;
      }

      form {
        width: 100%;
        text-align: left;

        .submit-button {
          display: block;
        }
      }

      .returnToBttn {
        margin: 0px auto 0px auto;
        width: 250px;
        font-weight: 500;

        .text {
          margin-right: 8px;
        }

      }
    }
  }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

  #forgot-password, #reset-password {

    .password-form-wrapper {
      padding: 16px;

      .password-form {
        padding: 24px;
        width: 100%;

        form {

          .md-button {
            width: 90%;
          }
        }
      }
    }
  }

}
