.toastIcon
{
  padding-right:4px
}

.toastClose
{
  position: relative;
  left:20px;
}

.toastLink
{
  cursor: pointer;
  text-decoration: underline;
}
