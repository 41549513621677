/**
 *  Do not remove the comment below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower

html {
  font-family: 'Roboto Slab', serif;
}

[layout=row] {
  flex-direction: row;
}

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/**
 *  Do not remove the comment below. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "scss/global.scss";
@import "auth/forgot-password/forgot-password.scss";
@import "auth/login/login.tmpl.scss";
@import "components/scheduler/scheduler.scss";
@import "components/update-password/update-password.scss";
@import "components/widget/widget.scss";
@import "services/toast/vega-toast.scss";
// endinjector
