#login {

  overflow: hidden;
  height: 100%;
  background: none;
  text-align: center;
  background-size: cover;

  #forgotButton {
    text-transform: none;
  }

  .msgText {
    padding-bottom: 1em;
  }

  #login-form-wrapper {
    flex: 1 0 auto;
    padding: 32px;
    width: 100%;
    #login-form {
      width: 384px;
      max-width: 384px;
      padding: 32px;
      background-color: #f0f0f0;
      text-align: center;

      #signInText {
        padding-bottom: 0px !important;
        margin-top: 0px !important;
      }

      #logo-container {
        background-color: white;
        position: relative;
        left: -32px;
        width: 382px;
        top: -32px;
        min-width: 382px;
        border-bottom: 1px solid #AAAAAA;
        text-align: center;
        padding: 20px;

        #logo {
          margin-left:auto;
          margin-right:auto;
          width: 100%;
          height: 60px;          
        }
      }

      .title {
        font-size: 17px;
        margin: 16px 0 32px 0;
      }

      form {
        width: 100%;
        text-align: left;

      }

    }
  }
}

#loginFooter {
  color: $vega-gray8;

  a:link {
    color: $vega-gray6;
    text-decoration: none;
  }

  a:visited {
    color: $vega-gray6;
    text-decoration: none;
  }

  a:hover {
    color: white;
    text-decoration: none;
  }

  a:active {
    color: $vega-gray6;
    text-decoration: none;
  }
}
