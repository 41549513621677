
.inline-scheduler {

  md-datepicker {
    button.md-datepicker-button {
      padding-right:0px;
    }

    .md-datepicker-input-container {
      margin-left: 0px;
      width: 150px;

      input {
        min-width: 140px;
      }
    }
  }

  mdp-time-picker {
    button.md-icon-button {
      margin-top: 0px;
    }
    md-input-container {
      width: 90px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}

